<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12>
        <v-menu offset-y left>
          <template v-slot:activator="{ attrs, on }">
            <v-layout wrap justify-center v-bind="attrs" v-on="on">
              <v-flex xs12 text-center align-self-center>
                <v-btn block plain :ripple="false">
                  <v-badge
                    color="red"
                    :content="appNotification ? appNotification : '0'"
                    right
                    small
                    overlap
                    offset-x="20"
                  >
                    <v-icon size="30" color="#000">mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="400px" min-width="380px">
            <v-layout wrap justify-start pa-2>
              <v-flex xs12>
                <span class="itemHeading1">Notifications</span><br />
                <!-- <span class="keyValue pt-3">New</span> -->
              </v-flex>
              <v-flex xs12 v-if="notifications.length>0" >
                <v-layout wrap pt-2 v-for="item in notifications" :key="item._id" style="cursor:pointer" @click="routeType(item)">
                  <v-flex
                    md2
                    style="border: 1px black"
                    align-self-center
                    text-center
                  >
                    <v-icon size="20" color="#000">mdi-bell</v-icon>

                  </v-flex>

                  <v-flex md6>
                    <v-layout wrap>
                      <v-flex xs12 text-left>
                        <span class="itemValue1">{{ item.title }}</span
                        ><br />
                        <span class="itemKey">
                          {{ item.body }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex md4 align-self-center>
                    <span class="itemValue1 pr-3" >
                      {{ timeSince(item.create_date) }}
                    </span>
                    <v-icon v-if="item.status=='unread'" size="10" color="#5aa9ff">mdi-checkbox-blank-circle</v-icon>

                  </v-flex>
                </v-layout>
                   <v-flex px-3 xs3 offset-3 text-center>
                   <v-btn
                  small
                  plain 
                  @click="
                    $router.push('/officers/notifications')
                  "
                >
                  <span
                  class="itemValue"
                    style="color: blue; "
                    >view All
                  </span>
                
                </v-btn>
              </v-flex>
              </v-flex>
           
           <v-flex v-else py-2>
               <span
                  class="itemValue"
                    >No Notifications Found !!
                  </span>
           </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Socket from "./../../Sockets/socket";

export default {
  data() {
    return {
      notifications: [],
      notificationData:{},

    };
  },
  beforeMount() {
    this.getData();
  },
   mounted() {
    this.socketData();
    // this.notifications.unshift(this.notificationData)
   
   },
  computed: {
    appNotification() {
      if (this.$store.state.notification == undefined) {
        return 0;
      } else {
        return this.$store.state.notification;
      }
    },
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
       socketData() {
      Socket.notificationFunction(this); // <---read data
    },
    routeType(item) {
      this.changeStatus(item);
     
        if (
        item.routetype == "newConflict" ||
        item.routetype == "reportAssign" ||
        item.routetype == "reportResolve" ||
        item.routetype == "reportRelease" ||
        item.routetype == "reportAssignToYou" ||
        item.routetype == "yourReportAssign" ||
        item.routetype == "yourReportResolve" ||
        item.routetype == "yourReportRelease" ||
        item.routetype == "yourReportClose" ||
        item.routetype == "reportLocationUpdate" ||
        item.routetype == "reportTransferRequest"
      ) {
           if(this.$store.state.userData.role=="official")
        this.$router.push("/officers/cases/caseDetails/?id=" + item.routeId1);
        else
        this.$router.push("/admin/cases/caseDetails/?id=" + item.routeId1);
      }
  
 
    },
    changeStatus(item) {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/notification/markasread/" + item._id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((response) => {
          if (response.data.status == true) {
            {
            this.getData();

              this.appLoading = false;
            }
          }
        })
      .catch((err) => {
        this.appLoading = false;
        console.log(err);
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/notifications/unread/list",
        headers: {
          token: localStorage.getItem("token"),
        },
       
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.notifications = response.data.data.slice(0,6);

              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>